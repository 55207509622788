<template>
  <div class="BomReportProductCategory">
    <RadioGroup
      :model="store.bomReport.params"
      name="productCategory"
      :options="options"
    />
  </div>
</template>

<script>
import RadioGroup from '../RadioGroup.vue'
export default {
  components: {
    RadioGroup
  },
  inject: [ 'store' ],
  computed: {
    allOption () {
      const value = this.values.filter(label => label !== '508').join(',')
      const label = this.labels.filter(label => label !== 'Accessories').join(' & ')
      return {
        value,
        label
      }
    },
    labels () {
      return this.store.seasonSelector.typeOptions.map((option) => {
        return option.label
      })
    },
    values () {
      return this.store.seasonSelector.typeOptions.map((option) => {
        return option.value
      })
    },
    options () {
      const baseOptions = this.store.seasonSelector.typeOptions
      return [
        this.allOption,
        ...baseOptions
      ]
    }
  }
}
</script>
